<script setup lang="ts">
import {ref, watch} from 'vue';
import {tracker} from '@teemill/common/plugins';
import {ProductProvider} from '@teemill/modules/product';
import {Product, LiteProduct} from '@teemill/common/models';

import {captureException} from '@sentry/vue';

import ProductGrid from './ProductGrid.vue';

const props = withDefaults(
  defineProps<{
    product?: Product;
    divisionId: number;
    titleText?: string;
    titleAlignment?: 'left' | 'center';
    algorithm?: string;
  }>(),
  {
    titleText: 'Other products you might like',
    titleAlignment: 'center',
    algorithm: 'fast',
  }
);

const products = ref<LiteProduct[]>([]);

watch(
  () => props.product,
  () => {
    if (!props.product) {
      return;
    }

    ProductProvider.list(
      props.divisionId,
      8,
      props.product ? null : 'bestsellerOrder',
      props.algorithm,
      props.product ? props.product.urlName : null
    )
      .then(data => {
        products.value = data;
      })
      .catch(error => {
        captureException(error);
      });
  },
  {immediate: true}
);
</script>

<template>
  <product-grid
    :products="products ?? []"
    :title-text="titleText"
    :title-alignment="titleAlignment"
    @click="tracker.event('click-related-product')"
  />
</template>
