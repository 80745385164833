<template>
  <div class="tml-product-info">
    <product-title
      :title="product.name"
      :price="isPurchaseable ? activePrice : null"
      :sale-price="isPurchaseable ? salePrice : null"
      :show-percentage="product.isBundle"
    >
      <template #rating>
        <slot name="rating" />
      </template>
    </product-title>

    <tml-render-html-editor-output :html="product.description" class="mb-4" />

    <tml-loader :loading="!showForm" loading-height="12em" class="mb-4">
      <slot name="personalization" />
      <tml-form
        v-if="showForm"
        ref="productOptions"
        form-name="product-options"
        persistent
      >
        <tml-new-form v-if="showForm" name="product-options-new">
          <template v-if="!product.isBundle">
            <tml-new-product-color-selector
              v-if="productColors.length > 1 && showDropdownColourSelect"
              v-model="colorValue"
              class="mb-4"
              field-name="color"
              :colours="productColors"
              :validation-rules="{required: true}"
              @input="$emit('color-selected', $event?.value)"
            />
            <p v-if="productColors.length > 1" class="mb-2">
              {{ colorValue ? 'Colour:' : 'Select colour:' }}
              <b v-if="colorValue">{{ colorValue.value }}</b>
            </p>

            <tml-color-selector
              v-show="productColors.length > 1 && !showDropdownColourSelect"
              v-model="colorValue"
              :pre-selected="colorPreSelected"
              field-name="color"
              :items-per-row="{
                xs: 6,
                md: 4,
                lg: 6,
              }"
              :colors="productColors"
              :validation-rules="{required: true}"
              @input="$emit('color-selected', $event?.value)"
            />

            <div
              v-if="showSizeSelector"
              class="flex items-center justify-between mb-2"
              :class="{'mt-4': productColors.length > 1}"
            >
              <p>
                {{ sizeValue ? 'Size:' : 'Select size:' }}
                <b v-if="sizeValue">{{ sizeValue.value }}</b>
              </p>
              <button
                v-if="product.sizeChartId"
                class="flex items-center gap-1"
                @click.stop.prevent="openSizeChart"
              >
                <font-awesome-icon
                  :icon="faRulerHorizontal"
                  class="text-[--tml-text-color]"
                />
                <span class="underline text-[--tml-text-color]"
                  >Size guide</span
                >
              </button>
            </div>
            <div
              v-else-if="
                product.sizeChartId && !isPurchaseable && !product.customiseIt
              "
              class="text-right"
            >
              <button
                class="items-center gap-1 mt-3 inline"
                @click.stop.prevent="openSizeChart"
              >
                <font-awesome-icon
                  :icon="faRulerHorizontal"
                  class="text-[--tml-text-color] pr-2"
                />
                <span class="underline text-[--tml-text-color]"
                  >Size guide</span
                >
              </button>
            </div>

            <tml-size-selector
              v-if="showSizeSelector"
              class="mb-4"
              :value="sizeValue"
              field-name="size"
              :sizes="formSelectSizeOptions"
              :items-per-row="sizeSelectorBreakpoints"
              :stock-levels="stockLevels"
              @input="sizeValue = $event"
            />
          </template>

          <bundle-item-list
            v-if="shouldShowBundleItems"
            :product="product"
            @input="setBundleOptions"
          />
        </tml-new-form>
      </tml-form>

      <product-stock
        v-if="currentStock !== null && !preOrder"
        class="my-4"
        :stock="currentStock"
      />
    </tml-loader>

    <transition name="fade-up" mode="out-in">
      <template v-if="isPurchaseable">
        <tml-button
          v-if="isInStock && overrideAddToCart"
          class="w-full add-to-bag-button"
          :border-radius="theme().get('button.border.radius')"
          :disabled="product.addButtonDisabled"
          primary
          :loading="loading"
          href="#"
          text="Add to bag"
          @click="onCustomAddToCart"
        />
        <tml-button
          v-else-if="isInStock"
          class="w-full add-to-bag-button"
          :border-radius="theme().get('button.border.radius')"
          :disabled="product.addButtonDisabled"
          :primary="!productComingSoon"
          :loading="loading"
          href="#"
          :text="!productComingSoon ? 'Add to bag' : 'Coming Soon'"
          @click="onAddToCart"
        />

        <div v-else-if="!product.isBundle">
          <template v-if="!subscribedToStockNotification">
            <p class="mb-4">Notify me when it's back in-stock</p>
            <tml-form form-name="product-stock-notification" class="mb-4">
              <tml-input
                v-model="subscribeEmail"
                field-name="email"
                placeholder="Email"
                :validation-rules="{required: true, email: true}"
              />
            </tml-form>
            <tml-button
              class="w-full"
              primary
              :loading="loading"
              :disabled="!subscribeEmail"
              href="#"
              text="Notify Me"
              @click="onSubscribeToStock"
            />
            <tml-re-captcha ref="captcha" action="subdomains/oosNotify" />
          </template>
          <template v-else>
            <p class="mb-4">Great, we'll email you when it's back in stock</p>
          </template>
          <small>
            Learn more in our
            <tml-anchor href="/privacy-policy/" text="Privacy Policy" /> /
            <tml-anchor href="/terms-of-use/" text="Terms" />
          </small>
        </div>
      </template>
    </transition>

    <div v-if="product.customiseIt" class="customise-it mt-4">
      <svg
        style="width: 0; height: 0; position: absolute"
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id="rainbow-gradient" x2="0" y2="1">
          <stop stop-color="#f44336" offset="0%" />
          <stop stop-color="#FFC107" offset="17%" />
          <stop stop-color="#4CAF50" offset="33%" />
          <stop stop-color="#00BCD4" offset="50%" />
          <stop stop-color="#2196F3" offset="67%" />
          <stop stop-color="#9C27B0" offset="84%" />
          <stop stop-color="#f44336" offset="100%" />
        </linearGradient>
      </svg>
      <tml-button
        class="w-full customise-button"
        :href="
          colorValue
            ? `/design-your-own-t-shirt/${product.urlName}/?colour=${colorValue.value}`
            : `/design-your-own-t-shirt/${product.urlName}/`
        "
        text="Customise"
        :icon="faPaintbrush"
      />
    </div>

    <div v-if="!product.customiseIt && !isPurchaseable">
      <tml-button class="w-full" primary href="/enquire" text="Enquire" />
    </div>

    <eco-icons :product="product" class="my-8" />

    <div v-if="paymentProcessorType === 'teemill'" class="flex flex-col gap-2">
      <p class="flex items-center">
        <span class="w-8">
          <font-awesome-icon :icon="faThumbsUp" />
        </span>
        Easy Returns and Exchanges
      </p>
      <p class="flex items-center">
        <span class="w-8">
          <font-awesome-icon :icon="faTruckFast" />
        </span>
        Next Day Delivery Available
      </p>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import {faThumbsUp} from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import {faTruckFast} from '@fortawesome/pro-light-svg-icons/faTruckFast';
import {faRulerHorizontal} from '@fortawesome/pro-light-svg-icons/faRulerHorizontal';
import {faPaintbrush} from '@fortawesome/pro-light-svg-icons/faPaintbrush';

import {formatUrl, viewportSize} from '@teemill/common/helpers';
import {usePaymentProcessorStore} from '@teemill/common/vuex';
import {tracker} from '@teemill/common/plugins';

import {favourites} from '@teemill/common';
import {forms} from '@teemill/common/plugins';
import {snackbar, useGtm} from '@teemill/common/services';
import ProductStock from './ProductStock.vue';
import EcoIcons from './EcoIcons.vue';
import {splitTests} from '@teemill/common/classes';
import {TmlBubble, TmlNewProductColorSelector} from '@teemill/components';
import {useAddToBag, useNotifications} from '@teemill/modules/notifications';
import ProductTitle from './ProductTitle.vue';

export default {
  name: 'ProductInfo',

  components: {
    BundleItemList: defineAsyncComponent(() => import('./BundleItemList.vue')),
    EcoIcons,
    ProductStock,
    TmlBubble,
    TmlNewProductColorSelector,
    ProductTitle,
  },

  inject: ['theme'],

  props: {
    product: Object,
    showForm: {
      type: Boolean,
      default: true,
    },
    overrideAddToCart: Boolean,
  },

  data() {
    return {
      faTruckFast,

      splitTests,

      sizeValue: null,
      colorValue: null,
      faThumbsUp,
      faRulerHorizontal,
      faPaintbrush,
      loading: false,
      subscribedToStockNotification: false,

      colorPreSelected: '',

      bundleOptions: [],

      bundleOptionOutOfStock: false,

      favourites,
      subscribeEmail: '',
    };
  },

  computed: {
    paymentProcessorType() {
      return usePaymentProcessorStore().getter('paymentProcessorType');
    },

    isMobile() {
      return viewportSize.isSmaller('md');
    },

    isPurchaseable() {
      return this.product.enableAddToCart ?? true;
    },

    isInStock() {
      return this.currentStock > 0 || this.currentStock === null;
    },

    showDropdownColourSelect() {
      return splitTests.isActiveVariation('product_colour_select', 'dropdown');
    },

    selectedColour() {
      return this.colorValue?.value;
    },

    selectedSize() {
      return this.$store.getters['forms/product-options/formData']?.size?.value;
    },

    selectedBundleOptions() {
      return this.bundleOptions.map(bundleOption => ({
        color: bundleOption.colour,
        size: bundleOption.size,
      }));
    },

    productColors() {
      const options = this.product.options;

      return Object.keys(options).map(colour => ({
        text: colour,
        value: colour,
        name: colour,
        background: options[colour].thumbnail
          ? this.colorBackground(options[colour].thumbnail)
          : undefined,
      }));
    },

    currentStock() {
      const size = this.sizeValue || this.onlySize;

      if (this.selectedColour && size?.value) {
        return this.product.options[this.selectedColour].sizes[size.value]
          .stock;
      } else if (this.product.isBundle && !this.shouldShowBundleItems) {
        return this.lowestBundleItemStock;
      }

      return null;
    },

    lowestBundleItemStock() {
      let minimumStock = null;

      if (this.product.bundleOptions) {
        Object.values(this.product.bundleOptions).forEach(bundleOption => {
          Object.values(bundleOption).forEach(colour => {
            Object.values(colour.sizes).forEach(size => {
              const stock = size?.stock ?? size;
              if (
                stock !== null &&
                (stock < minimumStock || minimumStock === null)
              ) {
                minimumStock = stock;
              }
            });
          });
        });
      }

      return minimumStock;
    },

    shouldShowBundleItems() {
      return (
        this.product.isBundle &&
        (this.productHasBundleItemsWithMoreThanOneColour ||
          this.productHasBundleItemsWithMoreThanOneSize)
      );
    },

    productHasBundleItemsWithMoreThanOneColour() {
      return this.product.bundleItems
        .map(bundleItem => bundleItem.options)
        .some(option => Object.keys(option).length > 1);
    },

    productHasBundleItemsWithMoreThanOneSize() {
      return Object.values(
        this.product.bundleItems.map(bundleItem => bundleItem.options)
      )
        .map(option => Object.values(option))
        .map(option => option.map(subOption => subOption.sizes))
        .some(optionSizesPerColor =>
          optionSizesPerColor.some(
            optionSizes => Object.values(optionSizes).length > 1
          )
        );
    },

    formSelectSizeOptions() {
      const options = [];

      if (this.selectedColour) {
        this.productSizes(this.selectedColour).forEach(size => {
          options.push({
            text: size,
            value: size,
          });
        });
      }

      return options;
    },

    productComingSoon() {
      if (this.product.approvalStatus === 'denied') {
        return true;
      }

      return false;
    },

    showSizeSelector() {
      if (!this.isPurchaseable) {
        return false;
      }

      return (
        this.selectedColour &&
        (this.formSelectSizeOptions.length > 1 || this.product.sizeCount > 1)
      );
    },

    onlySize() {
      return this.formSelectSizeOptions.length === 1
        ? this.formSelectSizeOptions[0]
        : null;
    },

    stockLevels() {
      return Object.keys(this.product.options[this.colorValue.value].sizes).map(
        key => {
          return this.product.options[this.colorValue.value].sizes[key];
        }
      );
    },

    preOrder() {
      const product = this.product;

      if (product.isBundle) {
        return false;
      }

      const size = this.sizeValue || this.onlySize;

      if (this.selectedColour && size?.value) {
        return product.options[this.selectedColour].sizes[size.value].preOrder;
      }

      return false;
    },

    activePrice() {
      const product = this.product;

      if (product.isBundle) {
        return product.totalBundlePrice;
      }

      if (this.colorValue && this.sizeValue) {
        return product.options[this.colorValue.value].sizes[
          this.sizeValue.value
        ].price;
      }

      return product.price;
    },

    salePrice() {
      const product = this.product;

      if (product.isBundle) {
        return product.price;
      }

      if (this.colorValue && this.sizeValue) {
        return product.options[this.colorValue.value].sizes[
          this.sizeValue.value
        ].salePrice;
      }

      return product.salePrice;
    },

    longestSizeName() {
      if (!this.formSelectSizeOptions.length) {
        return '';
      }

      return this.formSelectSizeOptions.reduce((a, b) =>
        a.text.length > b.text.length ? a : b
      ).text;
    },

    sizeSelectorBreakpoints() {
      return this.longestSizeName.length > 6
        ? {
            xs: 3,
            md: 2,
            lg: 3,
          }
        : {
            xs: 6,
            md: 4,
            lg: 6,
          };
    },
  },

  watch: {
    colorValue(newColour) {
      this.resetSizeIfUnavailableFor(newColour?.value);
    },

    product: {
      handler() {
        let preSelectedColor = this.productColors[0];
        if (this.$route.params.colour) {
          const colorName = this.$route.params.colour
            .replace(/-/, ' ')
            .replace(/\+/, '-')
            .replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());

          preSelectedColor = this.productColors.find(
            colour => colour.name === colorName
          );
        }

        if (this.showDropdownColourSelect) {
          this.colorValue = preSelectedColor;
        } else {
          this.colorPreSelected = preSelectedColor;
        }
      },
      immediate: true,
    },

    formSelectSizeOptions(newValue, oldValue) {
      if (!oldValue.length && this.$route.query['options[]']) {
        let options = this.$route.query['options[]'];
        if (!Array.isArray(options)) {
          options = [options];
        }

        options.forEach(option => {
          const [key, value] = option.split(':');
          if (key === 'Size') {
            this.sizeValue =
              this.formSelectSizeOptions.find(size => size.value === value) ??
              this.sizeValue;
          }
        });
      }
    },
  },

  methods: {
    productSizes(colour) {
      const sizes = this.product.options[colour]?.sizes;

      if (sizes === undefined) {
        return [];
      }

      return Object.keys(sizes);
    },

    openSizeChart() {
      useGtm().trackEvent({
        event: 'onOpenSizeChart',
      });

      this.$overlays.open(`size-popup-${this.product.id}`);
    },

    validateAddToCart() {
      return new Promise((resolve, reject) => {
        if (!this.productComingSoon && !this.product.addButtonDisabled) {
          const productOptionsForm =
            this.$store.getters['forms/product-options/formData'];

          if (
            (this.showSizeSelector && !this.sizeValue) ||
            (this.bundleOptions.length &&
              this.bundleOptions.map(option => option.size).includes(undefined))
          ) {
            reject('Please choose a size');
            return;
          }

          forms
            .validate('product-options-new')
            .success(() => {
              if (productOptionsForm) {
                const productOptionsFormIsValid =
                  this.$store.getters['forms/product-options/isValid'];

                const selectedColor = this.selectedColour;

                const selectedSize = this.getSelectedSize();

                this.$refs.productOptions.validateAll();

                if (!productOptionsFormIsValid) {
                  if (this.product.isBundle) {
                    reject('Please choose a size and colour for all items');
                  } else {
                    reject('Please choose a size and colour ');
                  }
                  return;
                }

                if (this.bundleItemOutOfStock) {
                  const bundleItem = this.bundleItemOutOfStock;
                  reject(
                    `Looks like ${bundleItem.name} (${bundleItem.size}) in ${bundleItem.colour} is out of stock`
                  );
                  return;
                }

                resolve({
                  color: selectedColor,
                  size: selectedSize,
                });
              }
            })
            .catch(() => {
              if (this.product.isBundle) {
                reject('Please choose a size and colour for all items');
              } else {
                reject('Please choose a size and colour ');
              }
            });
        }
      });
    },

    onAddToCart() {
      this.validateAddToCart()
        .then(({color, size}) => {
          this.loading = true;

          this.$store
            .dispatch('cart/addItem', {
              item: {
                productId: this.product.id,
                productName: this.product.name,
                productColor: color,
                productSize: size,
                productPrice: this.activePrice,
                bundleOptions: this.bundleOptions,
              },
              name: 'main',
            })
            .then(response => {
              this.onAfterAddToCart(response);

              this.sendAddToCartEvent(color, size);

              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(e => snackbar(e));
    },

    onCustomAddToCart() {
      this.validateAddToCart()
        .then(({color, size}) => {
          this.loading = true;
          this.$emit('custom-add-to-cart', {
            color,
            size,
            response: data => {
              this.loading = false;
              this.onAfterAddToCart(data);

              this.sendAddToCartEvent(color, size);
            },
            reject: () => {
              this.loading = false;
            },
          });
        })
        .catch(e => snackbar(e));
    },

    onAfterAddToCart(response) {
      if (response?.items?.length > 0) {
        tracker.conversion('add-to-cart', this.activePrice, [this.product.id]);

        const productTypeEventName = this.product.isBundle
          ? 'add-bundle-to-cart'
          : 'add-single-product-to-cart';

        tracker.event(productTypeEventName);

        this.showAddedToBagNotification();
      }
    },

    showAddedToBagNotification() {
      this.dismissSubscribeOfferNotification();

      useNotifications().removeAll();

      let addToBagData = {
        name: this.product.name,
        bagCount: this.$store.getters['cart/getQuantity'],
        price: this.activePrice,
        salePrice: this.salePrice,
      };

      if (this.product.isBundle) {
        addToBagData = {
          ...addToBagData,
          image: this.product.lifestyleImages[0]['120'],
          options: this.selectedBundleOptions,
        };
      } else {
        addToBagData = {
          ...addToBagData,
          image: this.product.options[this.selectedColour].flatImage['120'],
          options: [
            {
              color:
                this.formSelectSizeOptions.length > 1 &&
                this.productColors.length > 1
                  ? this.selectedColour
                  : undefined,
              size: this.getSelectedSize(),
            },
          ],
        };
      }

      const {setRouter, show} = useAddToBag();
      setRouter(this.$router);
      show(addToBagData);
    },

    dismissSubscribeOfferNotification() {
      const {handle, notifications} = useNotifications();

      const subscribeOfferNotification = notifications.find(
        notification => notification.id === 'subscriber-offer-discount'
      );

      if (subscribeOfferNotification) {
        handle(
          subscribeOfferNotification,
          'action://plugins/subscriber-offer/dismiss'
        );
      }
    },

    sendAddToCartEvent() {
      let productOptions = [];

      if (this.product.isBundle) {
        productOptions = this.selectedBundleOptions;
      } else {
        productOptions.push({
          color: this.selectedColour,
          size: this.getSelectedSize(),
        });
      }

      const productPrice = this.salePrice || this.activePrice;

      useGtm().addToCart({
        contentType: 'product',
        contentIds: [this.product.id.toString()],
        items: [
          {
            id: this.product.id,
            name: this.product.name,
            brand: JSON.stringify(this.$store.state.subdomain.company.name),
            price: productPrice,
            currency: this.$store?.state?.subdomain?.currency ?? 'GBP',
            dimension4: `teemill:${this.$store.state.subdomain.divisionName}`,
            quantity: 1,
            options: productOptions,
          },
        ],
        currency: this.$store?.state?.subdomain?.currency ?? 'GBP',
        value: productPrice,
        dimension4: `teemill:${this.$store.state.subdomain.divisionName}`,
        userId: this.$store.state.subdomain.sessionId,
      });
    },

    onSubscribeToStock() {
      if (this.$store.getters['forms/product-stock-notification/isValid']) {
        if (this.loading) {
          return;
        }

        this.loading = true;

        const productOptionsForm =
          this.$store.getters['forms/product-options/formData'];
        const selectedColor = productOptionsForm.color.value.value;
        const selectedSize = this.getSelectedSize();

        this.$refs.captcha.recaptcha
          .ifHuman(recaptcha => {
            this.axios
              .post(
                formatUrl('/omnis/v3/frontend/notification/subscribeToStock/'),
                {
                  email: this.$store.getters[
                    'forms/product-stock-notification/getFieldValue'
                  ]('email', 'value'),
                  productId: this.product.id,
                  productColor: selectedColor,
                  productSize: selectedSize,
                  v3captchaToken: recaptcha.v3Token,
                  v2captchaToken: recaptcha.v2Token,
                }
              )
              .success(() => {
                this.subscribedToStockNotification = true;
              })
              .validation()
              .oops()
              .any(() => {
                if (this.$refs.captcha) {
                  this.$refs.captcha.recaptcha.reload();
                }
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .ifBot(() => {
            this.loading = false;
          });
      }
    },

    colorBackground(thumbnail) {
      switch (thumbnail?.type) {
        case 'image':
          return `url(${formatUrl(thumbnail.value)})`;
        case 'color':
          return thumbnail.value;
        default:
          return undefined;
      }
    },

    setBundleOptions(value) {
      this.bundleOptions = value;

      this.checkBundleStock();
    },

    checkBundleStock() {
      this.bundleItemOutOfStock = null;

      this.bundleOptions.forEach(bundle => {
        if (bundle.stock === 0) {
          this.bundleItemOutOfStock = {
            name: bundle.name,
            size: bundle.size,
            colour: bundle.colour,
          };
        }
      });
    },

    async attemptToFavourite(identifier) {
      try {
        await this.favourites.add(identifier);
      } catch (error) {
        snackbar.error(error.message);
      }
    },

    getSelectedSize() {
      if (this.formSelectSizeOptions.length === 1) {
        return this.formSelectSizeOptions[0].value;
      } else {
        return this.product.isBundle ? null : this.sizeValue?.value;
      }
    },

    resetSizeIfUnavailableFor(colour) {
      if (!this.sizeValue) {
        return;
      }

      const sizesForColour = this.productSizes(colour);

      if (!sizesForColour) {
        this.sizeValue = null;
      }

      if (!sizesForColour.includes(this.sizeValue?.value)) {
        if (sizesForColour.length < 1) {
          this.sizeValue = null;
        } else {
          const size = sizesForColour[sizesForColour.length - 1];

          this.sizeValue = {
            value: size,
            text: size,
            name: size,
          };
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tml-product-info {
  .customise-button svg path {
    fill: url(#rainbow-gradient) #f44336;
  }

  .favourite-icon {
    width: 3em;
    height: 2.75em;
    margin-left: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;

    svg {
      color: var(--tml-text-color);
      height: 1.75em;
    }
  }
}
</style>

<style lang="scss" scoped>
.fade-up-enter-active {
  animation: fade-up 0.3s;
}
.fade-up-leave-active {
  animation: fade-up 0.3s reverse;
}

@keyframes fade-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
