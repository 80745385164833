<template>
  <div>
    <product-image-carousel
      ref="imageCarousel"
      :breakpoints="{xs: 1}"
      :ratio="1.1"
      page-indicators="inset"
      inset-navigation
    >
      <template #default>
        <div>
          <tml-ratio v-if="enablePersonalization" :ratio="1">
            <tml-image-zoom :img-normal="getZoomImage('front')">
              <template #default>
                <personalization-canvas
                  layer="front"
                  @initialised="$emit('front-canvas-initialised', $event)"
                  @generated-thumbnail="onGeneratedThumbnail('front', $event)"
                  @generated-zoom-image="onGeneratedZoomImage('front', $event)"
                />
              </template>
            </tml-image-zoom>
          </tml-ratio>
          <tml-ratio
            v-if="enablePersonalization && enableBackPersonalization"
            :ratio="1"
          >
            <tml-image-zoom :img-normal="getZoomImage('back')">
              <template #default>
                <personalization-canvas
                  layer="back"
                  @initialised="$emit('back-canvas-initialised', $event)"
                  @generated-thumbnail="onGeneratedThumbnail('back', $event)"
                  @generated-zoom-image="onGeneratedZoomImage('back', $event)"
                />
              </template>
            </tml-image-zoom>
          </tml-ratio>
          <tml-ratio
            v-for="(image, index) in images(1080)"
            :key="index"
            :ratio="1"
          >
            <template v-if="viewportSize.isSmaller('md')">
              <tml-image
                :alt="getAltText(image)"
                :src="image.url"
                class="w-full"
                :lazy-load="index !== 0"
                :ratio="1"
                :src-set="[
                  {width: 640, height: 640},
                  {width: 1080, height: 1080},
                ]"
              >
                <div
                  v-if="showSaleIndicators"
                  class="sale-badge thumb-size mb-4 rounded-full float-right m-4"
                >
                  <h3 class="text-white bold h-full">Sale</h3>
                </div>
              </tml-image>
            </template>
            <tml-image-zoom
              v-else
              class="w-full h-full"
              :img-normal="image.url"
              :alt="getAltText(image)"
              :lazy="index !== 0"
            />
          </tml-ratio>
        </div>
      </template>
    </product-image-carousel>
  </div>

  <tml-grid
    v-if="
      !viewportSize.isSmaller('md') &&
      (images(120).length > 1 || enablePersonalization)
    "
    class="mt-4"
    :breakpoints="{xs: 8}"
    :spacing="{xs: '0.5em', lg: '1em'}"
  >
    <tml-image
      v-for="(image, index) in images(120)"
      :key="index"
      class="cursor-pointer"
      :alt="getAltText(image)"
      :src="image.url"
      @click="onClickThumb(index)"
    />
  </tml-grid>
</template>

<script>
import {faChevronUp} from '@fortawesome/pro-light-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons/faChevronDown';

import {clamp} from 'lodash';
import {
  formatUrl,
  viewportSize,
  image as imageUrl,
} from '@teemill/common/helpers';

import ProductImageCarousel from './ProductImageCarousel.vue';
import PersonalizationCanvas from './PersonalizationCanvas.vue';
import {defineAsyncComponent} from 'vue';

export default {
  name: 'ProductPreview',

  components: {
    ProductImageCarousel,
    PersonalizationCanvas: defineAsyncComponent(() =>
      import('./PersonalizationCanvas.vue')
    ),
  },

  props: {
    product: Object,

    selectedColor: String,

    enablePersonalization: Boolean,
    enableBackPersonalization: Boolean,
    personalizationLayer: String,
  },

  data() {
    return {
      faChevronUp,
      faChevronDown,

      viewportSize,

      thumbsOffset: 0,
      thumbOffsetInterval: 0,
      generatedThumbnails: {},
      generatedZoomImages: {},
    };
  },

  computed: {
    showSaleIndicators() {
      if (this.product && this.product.salePrice) {
        return this.product.salePrice < this.product.price;
      }

      return false;
    },
  },

  watch: {
    selectedColor: {
      handler(value, oldValue) {
        if (!this.$refs.imageCarousel || this.enablePersonalization) return;

        const isFirstTimeSelectingColor = oldValue === null;

        //Skip if we're on the product page and the color is not set in the URL
        if (isFirstTimeSelectingColor && !this.$route.params.colour) return;

        const hasLifestyleImages = this.product.lifestyleImages.length > 0;
        const images = this.images(1080);

        const matchedImageIndex = images.findIndex(
          image =>
            (hasLifestyleImages && image?.color === value) ||
            image?.optionId === value
        );

        if (matchedImageIndex !== -1) {
          this.$refs.imageCarousel.goToPage(matchedImageIndex);
        }
      },
      immediate: true,
    },

    personalizationLayer() {
      if (this.personalizationLayer === 'front') {
        this.$refs.imageCarousel.goToPage(0);
      } else if (this.personalizationLayer === 'back') {
        this.$refs.imageCarousel.goToPage(1);
      }
    },
  },

  methods: {
    formatUrl,

    /**
     * Gets the alt text of an image. Provides generic alt text if none found.
     *
     * @param { {url:string; optionId:string; altText?:string;} } image Image
     *
     * @return { string } Image alt text
     */
    getAltText(image) {
      if (image.altText) {
        return image.altText;
      }

      return `${
        image.optionId
          ? `${image.optionId} ${this.product.name}`
          : this.product.name
      }`;
    },

    images(size) {
      const lifestyleImages = this.product.lifestyleImages
        .filter(image => image[size])
        .map(image => ({
          url: imageUrl(formatUrl(image[size]), size, size),
          altText: image?.altText,
          color: image?.color,
        }));

      const flatImages = [];

      Object.values(this.product.options).forEach(option => {
        if (option.flatImage) {
          flatImages.push({
            optionId: option.colour,
            url: formatUrl(option.flatImage[size]),
          });
        }

        if (option.backImage) {
          flatImages.push({
            optionId: option.colour,
            url: formatUrl(option.backFlatImage[size]),
          });
        }
      });

      const images = this.enablePersonalization
        ? lifestyleImages
        : [...lifestyleImages, ...flatImages];

      if (this.generatedThumbnails && size < 1080) {
        const personalizationThumbnails = Object.values(
          this.generatedThumbnails
        ).map(src => ({
          url: src,
          altText: 'Preview',
          color: this.selectedColor,
        }));
        images.unshift(...personalizationThumbnails);
      }

      return images;
    },

    onClickThumb(index) {
      if (this.$refs.imageCarousel) {
        this.$refs.imageCarousel.goToPage(index);
      }
    },

    onMouseEnterThumbNav(direction) {
      if (this.$refs.thumbListContainer && this.$refs.thumbsList) {
        const thumbsContainerHeight =
          this.$refs.thumbListContainer.clientHeight;
        const thumbsHeight = this.$refs.thumbsList.clientHeight;

        if (this.thumbOffsetInterval === 0) {
          this.thumbOffsetInterval = setInterval(() => {
            if (direction === 'up') {
              this.thumbsOffset += 2;
            } else if (direction === 'down') {
              this.thumbsOffset -= 2;
            }

            this.thumbsOffset = clamp(
              this.thumbsOffset,
              thumbsContainerHeight - thumbsHeight,
              0
            );
          }, 10);
        }
      }
    },

    onMouseLeaveThumbNav() {
      clearInterval(this.thumbOffsetInterval);
      this.thumbOffsetInterval = 0;
    },

    onGeneratedThumbnail(layer, src) {
      this.generatedThumbnails[layer] = src;
    },

    onGeneratedZoomImage(layer, src) {
      this.generatedZoomImages[layer] = src;
    },

    getZoomImage(layer) {
      return this.generatedZoomImages[layer] || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.sale-badge {
  height: 80px;
  width: 80px;
  background-color: $discounted-color !important;
  h3 {
    color: $white-text;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }
}
</style>
