<template>
  <div
    v-if="!downloading"
    class="tml-product-reviews"
    :style="{
      '--fade-to-color':
        backgroundColor || theme().get('page.background.color'),
    }"
  >
    <div class="relative">
      <transition-group>
        <tml-review
          v-for="(review, index) in sortedReviews"
          v-show="index < numberShown"
          :key="review.id"
          class="mb-12"
          :product-name="review.productName"
          :show-product-name="false"
          :company-name="companyName"
          :company-logo="companyLogo"
          :item="review"
          show-reply
        />
      </transition-group>

      <div v-if="showHider" class="hider">
        <tml-bubble
          class="expand-arrow"
          disable-border
          show-shadow
          :icon="faChevronDown"
          @click="expand"
        />
      </div>
    </div>
  </div>
  <tml-spinner v-else height="100px" />
</template>

<script>
import {formatUrl} from '@teemill/common/helpers';
import {faComment} from '@fortawesome/pro-regular-svg-icons/faComment';
import {TmlReview, TmlIcon, TmlBubble} from '@teemill/components';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons/faChevronDown';

export default {
  name: 'ProductReviews',

  components: {
    TmlReview,
    TmlIcon,
    TmlBubble,
  },

  inject: ['theme'],

  props: {
    productId: {
      type: Number,
      required: true,
    },

    increment: {
      type: Number,
      default: 3,
    },

    numberShownInitially: {
      type: Number,
      default: 3,
    },

    hardLimit: {
      type: Number,
      required: false,
    },

    backgroundColor: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      faComment,
      faChevronDown,
      downloading: true,

      reviews: [],

      numberShown: 3,
    };
  },

  computed: {
    showHider() {
      return this.sortedReviews.length > this.numberShown;
    },

    sortedReviews() {
      const sorted = [...this.reviews].sort((a, b) => {
        if (a.rating === b.rating) {
          return new Date(a.date) > new Date(b.date) ? -1 : 1;
        }

        return b.rating - a.rating;
      });

      return this.hardLimit ? sorted.slice(0, this.hardLimit) : sorted;
    },

    companyName() {
      return this.$store.state.subdomain.company?.name;
    },

    companyLogo() {
      const logoPath = this.$store.getters['subdomain/logo'];
      return logoPath ? formatUrl(logoPath) : null;
    },
  },

  created() {
    this.numberShown = this.numberShownInitially;

    this.axios
      .post(formatUrl('/omnis/v3/frontend/product/getProductReviews/'), {
        productId: this.productId,
      })
      .success(data => {
        const reviews = Object.values(data);

        reviews.forEach((review, index) => {
          reviews[index].rating = parseFloat(review.rating);
        });

        this.reviews = reviews;
      })
      .finally(() => {
        this.downloading = false;
      });
  },

  methods: {
    expand() {
      if (this.numberShown + this.increment > this.reviews.length) {
        this.numberShown = this.reviews.length;

        return;
      }

      this.numberShown += this.increment;
    },
  },
};
</script>

<style lang="scss" scoped>
.hider {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  background-image: linear-gradient(
    to top,
    var(--fade-to-color),
    rgba(255, 255, 255, 0)
  );
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-enter-from {
  opacity: 0;
}
</style>
