<template>
  <template v-if="product">
    <product-show :key="product.id" :product="product" />
    <product-cross-sell
      v-if="isPro"
      :product="product"
      :division-id="store.state.subdomain.division"
    />
    <design-cross-seller
      v-if="isPro && showCrossSeller"
      :product="product"
      class="mb-16 pb-2"
    />
    <product-recommendations
      :key="product.id"
      class="!mb-16"
      :product="product"
      :division-id="store.state.subdomain.division"
      title-alignment="left"
    />
    <product-ugc
      v-if="ugcImages.length"
      class="mb-16"
      :ugc-images="ugcImages"
      :title-text="theme().get('productPage.ugc.title.text')"
      :subtitle-text="theme().get('productPage.ugc.subtitle.text')"
    />
    <product-taglets
      v-if="
        app.theme.get('page.product.taglets.visible') === '1' &&
        product.tags?.length
      "
      class="mb-8"
      :tags="product.tags"
    />
  </template>
</template>

<script>
import {useGtm, snackbar} from '@teemill/common/services';
import {cloneDeep} from 'lodash';
import {captureException} from '@sentry/vue';

import {escapeJSON} from '@teemill/utilities';
import {formatUrl} from '@teemill/common/helpers';
import {tracker} from '@teemill/common/plugins';
import {ApiError, NotFoundError} from '@teemill/common/errors';
import {store} from '../../services';

import {computed} from 'vue';
import {useHead} from '@vueuse/head';

import {
  ProductShow,
  ProductRecommendations,
  DesignCrossSeller,
  ProductCrossSell,
  ProductTaglets,
  ProductUgc,
} from '@teemill/modules/product';

export default {
  name: 'ProductPage',

  components: {
    ProductShow,
    ProductRecommendations,
    ProductUgc,
    DesignCrossSeller,
    ProductCrossSell,
    ProductTaglets,
  },

  inject: ['app', 'theme'],

  data() {
    return {
      product: null,

      store,
    };
  },

  computed: {
    isPro() {
      return store.state.subdomain?.pro;
    },
    metaDescription() {
      if (this.product.metaDescription) {
        return this.product.metaDescription;
      }

      return this.product.shortDescription
        ? this.product.shortDescription
        : this.product.description;
    },

    productImage() {
      if (this.product?.lifestyleImages?.length) {
        return this.product.lifestyleImages[0][640];
      }

      return Object.values(this.product.options)[0]?.flatImage?.[480];
    },

    ugcImages() {
      return (
        this.product?.ugcImages?.map(image => ({
          src: image['480'],
          altText: image.altText,
          color: image.color,
        })) || []
      );
    },

    showCrossSeller() {
      return this.product.designCrossSellData?.enabled;
    },

    productHasOptionsInStock() {
      if (this.product.isBundle) {
        if (!this.product.bundleOptions) {
          return false;
        }

        return Object.values(this.product.bundleOptions).every(bundleOption =>
          this.someOptionsAreInStock(bundleOption)
        );
      }

      return this.someOptionsAreInStock(this.product.options);
    },
  },

  watch: {
    ['$route.params.product']: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }

        this.product = null;

        fetch(
          formatUrl(
            `/omnis/v3/division/${this.$store.state.subdomain.division}/products/${this.$route.params.product}/`
          ),
          {
            credentials: 'include',
            mode: 'no-cors',
          }
        )
          .then(response => {
            if (response.ok || response.status === 302) {
              return response.json();
            }

            if (response.status === 404) {
              throw new NotFoundError();
            }

            throw new ApiError();
          })
          .then(data => {
            if (data.message) {
              this.$router.push(data.message);
              return;
            }

            this.$store.commit('product/setActive', data);

            let product = cloneDeep(data);

            this.handleProductTracking(product);
            this.$store.commit('setTitle', product.name);
            this.$eventBus.emit('tml-breadcrumbs-update');

            fetch(
              formatUrl(
                `/omnis/v3/division/${this.$store.state.subdomain.division}/products/${this.$route.params.product}/stock/`
              ),
              {
                credentials: 'include',
                mode: 'no-cors',
              }
            )
              .then(response => {
                if (response.ok) {
                  return response.json();
                }

                throw new ApiError();
              })
              .then(stockData => {
                if (product.isBundle) {
                  product = Object.assign({}, product, {
                    bundleOptions: stockData,
                  });
                } else {
                  Object.values(product.options).forEach(option => {
                    if (!stockData[option.colour]) {
                      delete product.options[option.colour];
                    } else {
                      Object.keys(stockData[option.colour].sizes).forEach(
                        size => {
                          if (option.sizes[size]) {
                            option.sizes[size].stock =
                              stockData[option.colour].sizes[size];
                          }
                        }
                      );
                    }
                  });
                }

                product.addButtonDisabled = false;

                this.product = product;
              })
              .catch(error => {
                if (error instanceof ApiError) {
                  snackbar.error();
                  return;
                }

                captureException(error);
              });
          })
          .catch(error => {
            if (error instanceof NotFoundError) {
              this.$router.replace('/404');
              return;
            }

            if (error instanceof ApiError) {
              snackbar.error('Unable to load product data. Please try again');
              return;
            }

            captureException(error);
          });

        if (this.$store.state.forms?.['product-options']) {
          this.$store.commit('forms/product-options/unload');
        }
      },
    },
  },

  created() {
    this.setMetaData();
  },

  methods: {
    handleProductTracking(product) {
      tracker.event('view-product', {
        items: [product.id],
      });

      const productPrice = product.salePrice || product.price;

      useGtm().viewItem({
        contentType: 'product',
        items: [
          {
            id: product.id,
            name: product.name,
            brand: JSON.stringify(this.$store.state.subdomain.company.name),
            price: productPrice,
            currency: this.$store?.state?.subdomain?.currency ?? 'GBP',
          },
        ],
        currency: this.$store?.state?.subdomain?.currency ?? 'GBP',
        value: productPrice,
        userId: this.$store.state.subdomain.sessionId,
      });
    },

    escapeQuotes(review) {
      return review.replace(/"/g, '\\"');
    },

    someOptionsAreInStock(options) {
      return Object.values(options).some(colour =>
        this.colourHasSizesInStock(colour)
      );
    },

    colourHasSizesInStock(colour) {
      return Object.values(colour.sizes).some(
        size => (typeof size === 'number' ? size : size?.stock) > 0
      );
    },

    setMetaData() {
      useHead(
        computed(() => {
          if (this.product && this.product.id) {
            const host = `${location.protocol}//${location.hostname}`;
            const productUrl = `${host}${this.$route.fullPath}`;

            const brand = `{
              "@type": "Brand",
              "name": "${this.$store.state.subdomain.company.name}"
            }`;

            let reviewMarkup = '';

            if (this.product.reviews?.count) {
              /**
               * Review comments are not yet supported by google (07/06/2023), but we are including them for the off-chance that they are behind the scenes
               * or if they are supported by another search engine.
               * Either way they will make it easier for any machine to read the page and find the review replies.
               *  */
              const reviewMarkupArray = this.product.reviews.raw.map(
                review => `{
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "${review.rating}"
            },
            "author": {
              "@type": "Person",
              "name": "${review.author}"
            },
            "reviewBody": "${this.escapeQuotes(review.text)}"
            ${
              review.reply
                ? `,
              "comment": {
                "@type": "Comment",
                "author": {
                  "@type": "Person",
                  "name": "${review.reply.author.name}",
                  "brand": ${brand}
                },
                "text": "${escapeJSON(review.reply.text)}"
              },
              "commentCount": 1`
                : '' /* eslint-disable-line */
            }
        }`
              );

              reviewMarkup = `[${reviewMarkupArray.join(',')}]`;
            }

            return {
              title: this.product.metaTitle
                ? this.product.metaTitle
                : this.$store.state.title,
              script: [
                {
                  type: 'application/ld+json',
                  children: `
              {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": "${escapeJSON(this.product.name)}",
                "sku": "${this.product.id}",
                "url": "${productUrl}",
                "image": "${this.productImage}",
                "category": "${escapeJSON(
                  this.product.category || 'All Products'
                )}",
                "description": "${escapeJSON(
                  this.product.shortDescription
                    ? this.product.shortDescription
                    : this.product.description
                )}",
                "brand": ${brand},
                ${
                  this.product.reviews?.count
                    ? `
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${this.product.reviews.rating}",
                    "reviewCount": "${this.product.reviews.count}"
                  },
                  "review": ${reviewMarkup},`
                    : '' /* eslint-disable-line */
                }
                "offers": [
                  ${this.$store.getters['currency/getLdJson']({
                    price: this.product.price,
                    url: productUrl,
                    inStock: this.productHasOptionsInStock,
                  }).join(',')}
                ]
              }
            `,
                },
              ],
              meta: [
                {
                  vmid: 'og:url',
                  property: 'og:url',
                  content: `${host}${this.$route.fullPath}`,
                },
                {
                  vmid: 'og:title',
                  property: 'og:title',
                  content: this.product.metaTitle
                    ? this.product.metaTitle
                    : this.$store.state.title,
                },
                {
                  vmid: 'og:description',
                  property: 'og:description',
                  content: this.metaDescription,
                },
                {
                  vmid: 'og:image',
                  property: 'og:image',
                  content: this.product.metaImage
                    ? this.product.metaImage.src
                    : this.productImage,
                },
                {
                  vmid: 'og:image:width',
                  property: 'og:image:width',
                  content: '640',
                },
                {
                  vmid: 'og:image:height',
                  property: 'og:image:height',
                  content: '674',
                },
                {vmid: 'og:type', property: 'og:type', content: 'website'},

                {
                  vmid: 'twitter:title',
                  property: 'twitter:title',
                  content: this.product.metaTitle
                    ? this.product.metaTitle
                    : this.$store.state.title,
                },
                {
                  vmid: 'twitter:card',
                  property: 'twitter:card',
                  content: 'photo',
                },
                {
                  vmid: 'twitter:image',
                  property: 'twitter:image',
                  content: this.product.metaImage
                    ? this.product.metaImage.src
                    : this.productImage,
                },
                {
                  vmid: 'description',
                  name: 'description',
                  content: this.metaDescription,
                },
              ],
              link: [
                {
                  vmid: 'canonical',
                  rel: 'canonical',
                  href: `${host}/product/${this.product.urlName}/`,
                },
              ],
            };
          }

          return {};
        })
      );
    },
  },
};
</script>
