<template>
  <div class="page-product">
    <div class="row mb-16">
      <div class="col-md-7 px-0 md:px-4">
        <product-preview
          :selected-color="selectedColor"
          :product="product"
          :enable-personalization="!!product.personalizationTemplate"
          :enable-back-personalization="enableBackPersonalization"
          :personalization-layer="personalizationLayer"
          @front-canvas-initialised="initFrontCanvas"
          @back-canvas-initialised="initBackCanvas"
        />
      </div>
      <div class="col-md-5 px-0 md:px-4">
        <product-info
          :product="product"
          class="px-4"
          :class="{
            'pt-5': mobile,
          }"
          :override-add-to-cart="!!product.personalizationTemplate"
          @color-selected="selectedColor = $event"
          @custom-add-to-cart="onPersonalizationAddToCart"
        >
          <template v-if="product.personalizationTemplate" #personalization>
            <personalization-form
              :front-canvas="frontCanvas"
              :back-canvas="backCanvas"
              :selected-color="selectedColor"
              :product="product"
              @form-data-updated="personalizationFormData = $event"
              @focus-item="onFocusPersonalizationItem"
              @blur-item="onBlurPersonalizationItem"
            />
          </template>
          <template v-if="hasReviews" #rating>
            <product-rating
              class="mt-2"
              :rating="productRating"
              :rating-count="productRatingCount"
              @clicked="scrollToReviews()"
            />
          </template>
        </product-info>
      </div>
    </div>

    <div class="row mb-16">
      <div class="col-12">
        <product-spec :product="product" />
      </div>
    </div>

    <div ref="reviewsContainer" class="row mb-16">
      <div class="col-12">
        <tml-loader :loading="!product" height="500px" :loading-ratio="0.5">
          <div v-if="hasReviews" class="mb-8">
            <h4 class="mb-4">
              {{ product?.name }} Reviews
            </h4>
            <p>
              Recent customer reviews gave our {{ product?.name }}
              {{ productRating }}/5 on average.
            </p>
          </div>
          <h4 v-else>
            Our {{ product?.name }} hasn't had any reviews yet
          </h4>
          <product-reviews :product-id="product.id" />
          <tml-button
            class="mt-4"
            :style="{
              width: mobile ? '100%' : '300px',
            }"
            :border-radius="theme().get('button.border.radius')"
            text="Submit review"
            @click="$overlays.open('share-review-popup')"
          />
        </tml-loader>
      </div>
    </div>

    <template v-if="product">
      <size-chart-popup v-if="!product.isBundle" :product="product" />
      <template v-else>
        <size-chart-popup
          v-for="item in uniqueBundleItems"
          :key="item.id"
          :product="item"
        />
      </template>
      <product-review-popup :product="product" />
    </template>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import uniqBy from 'lodash/uniqBy';
import {StoreMixin} from '@teemill/components/mixins';
import {viewportSize} from '@teemill/common/helpers';
import {formatUrl} from '@teemill/common';

import AddToCartPopup from './AddToCartPopup.vue';
import ProductReviewPopup from './ProductReviewPopup.vue';
import SizeChartPopup from './SizeChartPopup.vue';
import ProductInfo from './ProductInfo.vue';
import ProductSpec from './ProductSpec.vue';
import ProductReviews from './ProductReviews.vue';
import ProductPreview from './ProductPreview.vue';
import ProductRating from './ProductRating.vue';
import {http} from '@teemill/common/services';

import {Profanity, ProfanityOptions} from '@2toad/profanity';

const profanityOptions = new ProfanityOptions();
profanityOptions.wholeWord = false;
const profanity = new Profanity(profanityOptions);

export default {
  name: 'ProductShow',

  components: {
    AddToCartPopup,
    ProductPreview,
    ProductReviewPopup,
    SizeChartPopup,
    ProductInfo,
    ProductSpec,
    ProductReviews,
    ProductRating: defineAsyncComponent(() => import('./ProductRating.vue')),
    PersonalizationForm: defineAsyncComponent(() =>
      import('./PersonalizationForm.vue')
    ),
  },
  mixins: [StoreMixin],

  inject: ['theme'],

  provide() {
    return {
      setVuexProperty: this.setVuexProperty,
      getVuexProperty: this.getVuexProperty,
    };
  },

  props: {
    product: Object,
  },

  data() {
    return {
      selectedColor: null,
      frontCanvas: null,
      backCanvas: null,
      personalizationFormData: [],
      personalizationLayer: 'front',
    };
  },

  computed: {
    uniqueBundleItems() {
      return uniqBy(this.product.bundleItems, 'id');
    },

    mobile() {
      return viewportSize.isSmaller('md');
    },

    productRating() {
      return this.product.reviews.rating;
    },

    productRatingCount() {
      return this.product.reviews.count;
    },

    hasReviews() {
      return !!this.product.reviews.raw.length;
    },

    enableBackPersonalization() {
      if (!this.product.personalizationTemplate) {
        return false;
      }

      const templateData = JSON.parse(this.product.personalizationTemplate);
      return !!templateData.objects.find(o => o.layer === 'back');
    },
  },

  created() {
    this.registerVuexModule();
  },

  methods: {
    scrollToReviews() {
      if (this.$refs.reviewsContainer) {
        const headerElement = document.querySelector('.header');
        const headerHeight = headerElement ? headerElement.offsetHeight : 80;

        window.scrollTo({
          top: this.$refs.reviewsContainer.offsetTop - (headerHeight + 16),
          behavior: 'smooth',
        });
      }
    },

    initFrontCanvas(canvas) {
      this.frontCanvas = canvas;
    },

    initBackCanvas(canvas) {
      this.backCanvas = canvas;
    },

    async onPersonalizationAddToCart({color, size, response, reject}) {
      const {frontDesignFile, backDesignFile} = await this.exportCanvasLayers();

      let rejected = false;

      this.personalizationFormData.forEach(data => {
        if (data.rules?.profanityFilter) {
          const object =
            data.layer === 'front'
              ? this.frontCanvas.findObject(data.id)
              : this.backCanvas?.findObject(data.id);

          if (profanity.exists(object.text)) {
            snackbar.error('Please remove profane language');
            reject();
            rejected = true;
          }
        }
      });

      if (rejected) {
        return;
      }

      const {data} = await http.post(
        formatUrl(`/omnis/v3/studio/createFrontendProduct/${this.product.id}`),
        {
          sku: this.product.baseSku,
          colours: [color],
          front_design_base64: frontDesignFile,
          back_design_base64: backDesignFile,
          name: `${this.product.name} (Custom)`,
        }
      );

      const {data: newProduct} = data;

      this.$store
        .dispatch('cart/addItem', {
          item: {
            productId: newProduct.id,
            productName: newProduct.name,
            productColor: color,
            productSize: size,
            productPrice: this.product.price,
          },
          name: 'main',
        })
        .then(addToCartResponse => {
          response(addToCartResponse);
        })
        .catch(() => {
          reject();
        });
    },

    onFocusPersonalizationItem({layer}) {
      this.personalizationLayer = layer;
    },

    onBlurPersonalizationItem() {
      this.personalizationLayer = null;
    },

    exportCanvasLayers() {
      return new Promise(resolve => {
        this.exportCanvasLayer(this.frontCanvas, 'front').then(
          frontDesignFile => {
            this.exportCanvasLayer(this.backCanvas, 'back').then(
              backDesignFile => {
                resolve({frontDesignFile, backDesignFile});
              }
            );
          }
        );
      });
    },

    exportCanvasLayer(
      canvas,
      layer,
      format = 'image/png',
      targetDesignWidth = 2584
    ) {
      return new Promise(resolve => {
        if (!canvas) {
          resolve(null);
          return;
        }

        const objects = canvas.getObjects(layer);
        if (!objects.length) {
          resolve(null);
          return;
        }
        const designScale = targetDesignWidth / canvas.boundingBox.width;

        resolve(canvas.export(format, 0.92, designScale, 1, false));
      });
    },
  },
};
</script>
