<template>
  <div class="tml-product-spec">
    <h4>Details</h4>
    <p>{{ product.specifications }} {{ coloursString }}</p>
    <p
      v-if="
        product.isBundle &&
          (product.salePrice || product.price) < product.totalBundlePrice
      "
      class="mb-4"
    >
      The original price is the sum of the individual products RRP that are
      listed on the store. The new price takes into account the saving you make
      on the products when buying them in a bundle.
    </p>
    <div v-for="item in bundleItems" :key="item.id" class="mb-4">
      <p>{{ item.specifications }}</p>
    </div>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import {splitTests} from '@teemill/common/classes';
import {viewportSize} from '@teemill/common/helpers';

export default {
  name: 'ProductSpec',
  props: {
    product: Object,
  },

  computed: {
    bundleItems() {
      return uniqBy(this.product.bundleItems || [], 'baseSku');
    },

    coloursString() {
      const coloursArray = Object.keys(this.product.options);

      if (coloursArray.length === 0) {
        return '';
      }

      let coloursString = 'Available in ';

      if (coloursArray.length === 1) {
        return `${coloursString} ${coloursArray[0].toLowerCase()}.`;
      }

      coloursArray.forEach((option, index) => {
        if (index === coloursArray.length - 1) {
          coloursString += `and ${option.toLowerCase()}.`;
        } else {
          coloursString += `${option.toLowerCase()}, `;
        }
      });

      return coloursString;
    },
  },
};
</script>
